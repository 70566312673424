<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="primary">mdi-hand-heart</v-icon> Community Services 
                 <v-spacer/>
                 <span style="width: 290px;">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                 </span>
                 <v-divider vertical inset class="ml-5 mr-2"/>
                        <v-btn text small color="success"  @click="event={}, state='new', form=true"><v-icon small left color="success">mdi-plus</v-icon> New</v-btn>
               </v-toolbar>
               <v-card-text>
                <v-data-table
                    class="va-table md-font"
                    dense
                    :headers="headers"
                    :search="search"
                    :items="services"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                              <v-icon x-small left color="warning" @click="service=item, state='update', form=true">mdi-pencil</v-icon>
                             
                              {{ fullname(item.beneficiary) }}</td>
                              <td>{{item.type}} </td>
                              <td>{{item.description}} </td>
                              <td>{{item.processed_by}} </td>
                              <td>{{ $moment(item.req_dt).format('MMM DD, YYYY hh:mm A') }}</td>
                              <td>{{ item.status=='Claimed'?$moment(item.claimed_dt).format('MMM DD, YYYY hh:mm A'):'' }}</td>
                             <td :class="STATUS_COLOR(item.status)">{{ item.status }}</td>

                             <!-- <td><v-btn class="ma-1" tile x-small left color="error" @click="event=item,eventname=item.name,confirm=true">
                              REMOVE
                            </v-btn></td> -->
                           
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
         
    </v-row>
    </v-container>
    <va-service-form :show="form" :data="service" :voters="voters" :state="state" @DialogEvent="formEvent" />
    <va-confirm :show="confirm" cancelbtn="Not Now" okbtn="REMOVE" :message="'Would you like remove this event '+eventname+'?'" @ConfirmEvent="confirmEvent" />
  </div>
</template>
<script>

import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        eventname:'',
        confirm: false,
        trans_dailog: false,
        areaform: false,
        state: 'new',
        selected: {},
        service: {},
        voters:[],
        search:'',
        services:[],
        hash: null,
        headers: [
        // { text: 'ID', value:"id"},
            { text: 'Beneficiary', value:"name"},
            { text: 'Type', value:"name"},
            { text: 'Item', value:"description"},
            { text: 'Process By', value:"description"},
            { text: 'Req. DT', value:"event_dt"},
            { text: 'Claimed. DT', value:"event_dt"},
            { text: 'Status', value:"status"}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getItems()
          this.getVoters()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      confirmEvent(e) {
        console.log(e)
        this.confirm = false
        if(e=='ok') {
          this.remove()
        }
      },
      remove() {
        this.event.status = "Remove"
        this.$http.post("event/update", this.event).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.getItems()
        }).catch(e => {
        console.log(e)
        })
      },
      afEvent() {
        this.areaform = false
        this.getAreas()
      },
      fullname(beneficiary){
          if(beneficiary) {
            return beneficiary.fullname
          }
      },
      formEvent(){
          this.form = false
          this.getItems()
          // if(data.action != 'close') {
          //     this.Save()
          // }
      },
      getVoters: function() { 
        this.$http.get("voter/list").then(response => {
          console.log(response.data)
            response.data.list != null?this.voters = response.data.list:this.voters =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
      getItems: function() { 
        this.$http.get("service/list").then(response => {
            response.data.items != null?this.services = response.data.items:this.services =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
    
      Save: function() {
        let method = 'event/add'
        this.state != 'new'?method='event/update':method = 'event/add'
        this.event.name = this.event.name.trim()
       
        this.$http.post(method, this.event).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.getItems()
        }).catch(e => {
        console.log(e)
        })
     },
    }
  }
</script>